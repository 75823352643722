import React from "react";
import { useNavigate } from "react-router-dom";
import './FinancialAndCareerStability.css';

const FinancialAndCareerStability = () => {
    const navigate = useNavigate();

    return (
       <div className="financial-and-career-stability-container">
        <h1>Resources For Financial Security and Career Stability</h1>
        <p>Here are some things that might help you:</p>
        
        <div className="financial-and-career-stability-resource-card">
            <a href="https://www.iwillteachyoutoberich.com/how-to-increase-your-income/" target="_blank" rel="noopener noreferrer">
              How To Make Extra Money
            </a>
        </div>
        
        <div className="financial-and-career-stability-resource-card">
            <a href="chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://www.sec.gov/pdf/facts.pdf" target="_blank" rel="noopener noreferrer">
              Saving and Investing
            </a>
        </div>
        
        <div className="financial-and-career-stability-resource-card">
            <a href="https://www.ramseysolutions.com/budgeting/financial-security?srsltid=AfmBOorSkKMTvKhzWJUBAhW5ZPoMcON6Xlrp_PaaIkqRhFhL3bSHzrCZ" target="_blank" rel="noopener noreferrer">
              Financial Security 101
            </a>
        </div>

        <div className="financial-and-career-stability-resource-card">
        <a href="https://amzn.to/4fcAD7Z" target="_blank" rel="noopener noreferrer">
        Clever Fox Budget Book 2.0 – Simple Budgeting Planner for Beginners (affiliate link)
        </a>
        </div>

        <div className="financial-and-career-stability-resource-card">
          <a href="https://www.youtube.com/watch?v=3zMFFzTMEig" target="_blank" rel="noopener noreferrer">
          How To Become Financially Stable In 9 Steps Youtube Video from Clever Girl Finance
          </a> 
        </div>
        
        <div className="financial-and-career-stability-resource-card">
            <a href="https://www.reddit.com/r/financialindependence/comments/16xymii/fire_flow_chart_version_43/" target="_blank" rel="noopener noreferrer">
              FIRE Flow Chart: A Popular Roadmap to Financial Freedom (Reddit)
            </a>
        </div>

        <div className="financial-and-career-stability-resource-card">
          <a href="https://www.thebalancemoney.com/feeling-weary-1917962" target="_blank" rel="noopener noreferrer">
          Tips To Improve Your Job Satisfaction
          </a>
        </div>

        <div className="financial-and-career-stability-resource-card">
          <a href="https://www.flexjobs.com/blog/post/job-hopping-v2/" target="_blank" rel="noopener noreferrer">
          Job Hopping Pros and Cons
          </a>
        </div>

        <div className="financial-and-career-stability-resource-card">
          <a href="https://www.youtube.com/watch?v=3FQMAs9zSfE" targe="_blank" rel="noopener noreferrer">
          Job Hopping 101 Youtube Video from Indeed
          </a>
        </div>

        <div className="financial-and-career-stability-resource-card">
          <a href="https://www.forbes.com/sites/deborahlovich/2024/01/24/how-and-why-to-become-irreplaceable-at-work/"
          target="_blank" rel="noopener noreferrer">
            How To Become Irreplaceable At Work
          </a>
        </div>

        <div className="financial-and-career-stability-resource-card">
          <a href="https://money.com/collection/money-101/" target="_blank" rel="noopener noreferrer">
          Money 101
          </a>
        </div>

        <div className="financial-and-career-stability-resource-card">
          <a href="https://amzn.to/40cDauO" target="_blank" rel="noopener noreferrer">
          100 Envelopes Money Saving Challenge Easy and Fun Way to Save $5,050 (affiliate link)
          </a>
        </div>

        <div className="financial-and-career-stability-resource-card">
          <a href="https://wisestewardshipfp.com/the-best-and-worst-ways-to-use-your-credit-cards/"
          target="_blank" rel="noopener noreferrer">
            How To Use Your Credit Cards And How Not To
          </a>
        </div>

        <div className="financial-and-career-stability-resource-card">
          <a href="https://www.experian.com/blogs/ask-experian/credit-education/how-to-get-out-of-debt/"
          target="_blank" rel="noopener noreferrer">
            How To Get Out Of Debt 
          </a>
        </div>

        {/* Return to Main Menu Button */}
        <button className="financial-and-career-stability-back-button" onClick={() => navigate('/')}>
            Return to Main Menu
        </button>
       </div>
    )
}

export default FinancialAndCareerStability;
