import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Results.css';
import { Link } from 'react-router-dom';

// Importing GIFs for the range 0-10
import gif010_1 from './images/0-10Gif1.gif';
import gif010_2 from './images/0-10Gif2.gif';
import gif010_3 from './images/0-10Gif3.gif';
import gif010_4 from './images/0-10Gif4.gif';
import gif010_5 from './images/0-10Gif5.gif';

// Importing GIFs for the range 11-20
import gif1120_1 from './images/11-20Gif1.gif';
import gif1120_2 from './images/11-20Gif2.gif';
import gif1120_3 from './images/11-20Gif3.gif';
import gif1120_4 from './images/11-20Gif4.gif';
import gif1120_5 from './images/11-20Gif5.gif';

// Importing GIFs for the range 21-30
import gif2130_1 from './images/21-30Gif1.gif';
import gif2130_2 from './images/21-30Gif2.gif';
import gif2130_3 from './images/21-30Gif3.gif';
import gif2130_4 from './images/21-30Gif4.gif';
import gif2130_5 from './images/21-30Gif5.gif';

// Importing GIFs for the range 31-40
import gif3140_1 from './images/31-40Gif1.gif';
import gif3140_2 from './images/31-40Gif2.gif';
import gif3140_3 from './images/31-40Gif3.gif';
import gif3140_4 from './images/31-40Gif4.gif';
import gif3140_5 from './images/31-40Gif5.gif';

// Importing GIFs for the range 41-50
import gif4150_1 from './images/41-50Gif1.gif';
import gif4150_2 from './images/41-50Gif2.gif';
import gif4150_3 from './images/41-50Gif3.gif';
import gif4150_4 from './images/41-50Gif4.gif';
import gif4150_5 from './images/41-50Gif5.gif';

// Importing GIFs for the range 51-60
import gif5160_1 from './images/51-60Gif1.gif';
import gif5160_2 from './images/51-60Gif2.gif';
import gif5160_3 from './images/51-60Gif3.gif';
import gif5160_4 from './images/51-60Gif4.gif';
import gif5160_5 from './images/51-60Gif5.gif';

// Importing GIFs for the range 61-70
import gif6170_1 from './images/61-70Gif1.gif';
import gif6170_2 from './images/61-70Gif2.gif';
import gif6170_3 from './images/61-70Gif3.gif';
import gif6170_4 from './images/61-70Gif4.gif';
import gif6170_5 from './images/61-70Gif5.gif';

// Importing GIFs for the range 71-80
import gif7180_1 from './images/71-80Gif1.gif';
import gif7180_2 from './images/71-80Gif2.gif';
import gif7180_3 from './images/71-80Gif3.gif';
import gif7180_4 from './images/71-80Gif4.gif';
import gif7180_5 from './images/71-80Gif5.gif';

// Importing GIFs for the range 81-90
import gif8190_1 from './images/81-90Gif1.gif';
import gif8190_2 from './images/81-90Gif2.gif';
import gif8190_3 from './images/81-90Gif3.gif';
import gif8190_4 from './images/81-90Gif4.gif';
import gif8190_5 from './images/81-90Gif5.gif';

// Importing GIFs for the range 91-100
import gif91100_1 from './images/91-100Gif1.gif';
import gif91100_2 from './images/91-100Gif2.gif';
import gif91100_3 from './images/91-100Gif3.gif';
import gif91100_4 from './images/91-100Gif4.gif';
import gif91100_5 from './images/91-100Gif5.gif';

const Results = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    income, relationshipStatus, homeSituation, fitnessLevel, friends, commute,
    careerLifeSatisfaction, physicalHealth, mentalHealth, hobbies, financialSecurity, workLifeBalance,
  } = location.state || {};

  // Function to calculate individual scores for each category
  const calculateCategoryScores = () => {
    let scores = {
      income: 0,
      relationshipStatus: 0,
      homeSituation: 0,
      fitnessLevel: 0,
      friends: 0,
      commute: 0,
      careerLifeSatisfaction: 0,
      physicalHealth: 0,
      mentalHealth: 0,
      hobbies: 0,
      financialSecurity: 0,
      workLifeBalance: 0,
    };

    // Scale down the categories proportionally to sum up to 99
    // Income score (10 points max)
    if (income === "0-9950") scores.income = 1;
    else if (income === "9951-40525") scores.income = 2;
    else if (income === "40526-86375") scores.income = 4;
    else if (income === "86376-164925") scores.income = 6;
    else if (income === "164926-209425") scores.income = 8;
    else if (income === "209426-523600") scores.income = 9;
    else if (income === "523601+") scores.income = 10;

    // Relationship Status (10 points max)
    if (relationshipStatus === "Single") scores.relationshipStatus = 3;
    else if (relationshipStatus === "Talking") scores.relationshipStatus = 5;
    else if (relationshipStatus === "Dating") scores.relationshipStatus = 8;
    else if (relationshipStatus === "Married") scores.relationshipStatus = 10;

    // Home Situation (8 points max)
    if (homeSituation === "Homeless") scores.homeSituation = 0;
    else if (homeSituation === "Living with parents") scores.homeSituation = 3;
    else if (homeSituation === "Living with roommates") scores.homeSituation = 5;
    else if (homeSituation === "Renting") scores.homeSituation = 6;
    else if (homeSituation === "Own my place") scores.homeSituation = 8;

    // Fitness Level (7 points max)
    if (fitnessLevel === "Sedentary") scores.fitnessLevel = 0;
    else if (fitnessLevel === "Light Activity") scores.fitnessLevel = 3;
    else if (fitnessLevel === "Moderately Active") scores.fitnessLevel = 5;
    else if (fitnessLevel === "Very Active") scores.fitnessLevel = 7;

    // Friends (7 points max)
    if (friends === "0") scores.friends = 0;
    else if (friends === "1-2") scores.friends = 3;
    else if (friends === "3-4") scores.friends = 5;
    else if (friends === "5+") scores.friends = 7;

    // Commute (5 points max)
    if (commute === "No Commute") scores.commute = 5;
    else if (commute === "0-15 min") scores.commute = 4;
    else if (commute === "15-30 min") scores.commute = 3;
    else if (commute === "30-60 min") scores.commute = 2;
    else if (commute === "1 hour+") scores.commute = 0;

    // Career Satisfaction (10 points max)
    if (careerLifeSatisfaction === "Very Satisfied") scores.careerLifeSatisfaction = 10;
    else if (careerLifeSatisfaction === "Somewhat Satisfied") scores.careerLifeSatisfaction = 8;
    else if (careerLifeSatisfaction === "Neutral") scores.careerLifeSatisfaction = 6;
    else if (careerLifeSatisfaction === "Somewhat Dissatisfied") scores.careerLifeSatisfaction = 2;
    else if (careerLifeSatisfaction === "Very Dissatisfied") scores.careerLifeSatisfaction = 0;

    // Physical Health (10 points max)
    if (physicalHealth === "Excellent") scores.physicalHealth = 10;
    else if (physicalHealth === "Good") scores.physicalHealth = 8;
    else if (physicalHealth === "Fair") scores.physicalHealth = 6;
    else if (physicalHealth === "Poor") scores.physicalHealth = 2;
    else scores.physicalHealth = 0;

    // Mental Health (10 points max)
    if (mentalHealth === "Excellent") scores.mentalHealth = 10;
    else if (mentalHealth === "Good") scores.mentalHealth = 8;
    else if (mentalHealth === "Fair") scores.mentalHealth = 6;
    else if (mentalHealth === "Poor") scores.mentalHealth = 2;
    else scores.mentalHealth = 0;

    // Hobbies (5 points max)
    if (hobbies === "Daily") scores.hobbies = 5;
    else if (hobbies === "A few times a week") scores.hobbies = 4;
    else if (hobbies === "Once a week") scores.hobbies = 3;
    else if (hobbies === "Rarely") scores.hobbies = 1;
    else scores.hobbies = 0;

    // Financial Security (10 points max)
    if (financialSecurity === "Very Secure") scores.financialSecurity = 10;
    else if (financialSecurity === "Somewhat Secure") scores.financialSecurity = 8;
    else if (financialSecurity === "Neutral") scores.financialSecurity = 6;
    else if (financialSecurity === "Somewhat Insecure") scores.financialSecurity = 2;
    else scores.financialSecurity = 0;

    // Work-Life Balance (8 points max)
    if (workLifeBalance === "Excellent") scores.workLifeBalance = 8;
    else if (workLifeBalance === "Good") scores.workLifeBalance = 6;
    else if (workLifeBalance === "Fair") scores.workLifeBalance = 4;
    else if (workLifeBalance === "Poor") scores.workLifeBalance = 2;
    else scores.workLifeBalance = 0;

    return scores;
  };

  const categoryScores = calculateCategoryScores();

  // Calculate the total score and cap it at 99
  let lifeScore = Object.values(categoryScores).reduce((acc, score) => acc + score, 0);
  if (lifeScore > 99) {
    lifeScore = 99; // Cap the score
  }

  // Find weakest and strongest points
  const findWeakestAndStrongest = () => {
    const entries = Object.entries(categoryScores);
    const weakest = entries.reduce((min, entry) => (entry[1] < min[1] ? entry : min), entries[0]);
    const strongest = entries.reduce((max, entry) => (entry[1] > max[1] ? entry : max), entries[0]);
    return { weakest, strongest };
  };

  const { strongest } = findWeakestAndStrongest();

  // Messages and GIF arrays for each range
  const messages = {
    "0-10": [
      "Don't worry! Every step counts. Let's take the first step together.",
      "It's a tough start, but you can only go up from here. Keep moving!",
      "The journey of a thousand miles begins with a single step!",
      "Challenges are what make life interesting. You’ve got this!",
      "Small progress is still progress. Keep pushing!"
    ],
    "11-20": [
      "You're making progress! Every bit counts.",
      "Keep up the effort; you're building something strong.",
      "Your foundation is set. Now let's rise higher!",
      "You’ve got this! Let's aim for the next level!",
      "Great start! The climb to success is a steady one."
    ],
    "21-30": [
      "You're getting the hang of it. Keep the momentum going!",
      "Solid start, but there's more to achieve!",
      "You've done some great work, now let’s build on that.",
      "The road to improvement is ongoing. Keep up the grind!",
      "You're getting there! Consistency is key."
    ],
    "31-40": [
      "You're on the right path! Keep pushing forward.",
      "Good work so far, but there’s more to achieve!",
      "You're building momentum. Stay consistent!",
      "You're doing well, keep up the good work!",
      "Your efforts are starting to pay off. Let’s keep going!"
    ],
    "41-50": [
      "Great progress! You're halfway there!",
      "You're doing fantastic. Keep your eyes on the prize!",
      "Your journey is showing results. Stay focused!",
      "Consistency is key, and you're on the right track!",
      "Keep up the good work! You're closer to your goals."
    ],
    "51-60": [
      "You're making significant progress. Stay strong!",
      "Great job! You're past the halfway mark!",
      "You're doing great, and it shows. Keep going!",
      "Your hard work is paying off. Let’s keep up the momentum!",
      "You’re on a roll. Keep up the good work!"
    ],
    "61-70": [
      "You're doing amazing! Keep up the great work.",
      "You're almost there. Just a bit more effort!",
      "Fantastic progress! You’re getting closer to your goals.",
      "You're showing real dedication. Keep moving forward!",
      "Great progress! You're getting closer to excellence."
    ],
    "71-80": [
      "Excellent work! You’re in the top tier of progress.",
      "You’re achieving fantastic results. Keep up the dedication!",
      "You’ve come so far. Keep your momentum going strong!",
      "You're doing an amazing job. Let’s take it to the next level!",
      "You’re almost at the top. Stay focused and keep pushing!"
    ],
    "81-90": [
      "You’re almost at perfection! Amazing work.",
      "Keep pushing – you’re on the verge of something incredible.",
      "Your hard work has brought you this far. Finish strong!",
      "You're doing exceptional work. Let’s bring it home!",
      "You're so close to the finish line. Stay committed!"
    ],
    "91-100": [
      "You’ve achieved excellence! Incredible job!",
      "Perfection! You've worked hard and it shows.",
      "Outstanding work – you've reached the top tier!",
      "Your efforts have paid off. You're at the peak of success!",
      "You're truly thriving! Keep up the amazing work."
    ]  
  };

  const gifs = {
    "0-10": [gif010_1, gif010_2, gif010_3, gif010_4, gif010_5],
    "11-20": [gif1120_1, gif1120_2, gif1120_3, gif1120_4, gif1120_5],
    "21-30": [gif2130_1, gif2130_2, gif2130_3, gif2130_4, gif2130_5],
    "31-40": [gif3140_1, gif3140_2, gif3140_3, gif3140_4, gif3140_5],
    "41-50": [gif4150_1, gif4150_2, gif4150_3, gif4150_4, gif4150_5],
    "51-60": [gif5160_1, gif5160_2, gif5160_3, gif5160_4, gif5160_5],
    "61-70": [gif6170_1, gif6170_2, gif6170_3, gif6170_4, gif6170_5],
    "71-80": [gif7180_1, gif7180_2, gif7180_3, gif7180_4, gif7180_5],
    "81-90": [gif8190_1, gif8190_2, gif8190_3, gif8190_4, gif8190_5],
    "91-100": [gif91100_1, gif91100_2, gif91100_3, gif91100_4, gif91100_5]
  };

  const getMessageAndGif = () => {
    const scoreRange = Object.keys(messages).find(range => {
      const [min, max] = range.split("-").map(Number);
      return lifeScore >= min && lifeScore <= max;
    });

    const randomMessage = messages[scoreRange][Math.floor(Math.random() * 5)];
    const randomGif = gifs[scoreRange][Math.floor(Math.random() * 5)];

    return { randomMessage, randomGif };
  };

  const { randomMessage, randomGif } = getMessageAndGif();

  // Define these functions to get display names and group pages for the improvement message
  const categoryDisplayNames = {
    income: "Income",
    relationshipStatus: "Relationship Status",
    homeSituation: "Home Situation",
    fitnessLevel: "Fitness Level",
    friends: "Friends",
    commute: "Commute",
    careerSatisfaction: "Career Satisfaction",
    physicalHealth: "Physical Health",
    mentalHealth: "Mental Health",
    hobbies: "Hobbies",
    financialSecurity: "Financial Security",
    workLifeBalance: "Work-Life Balance",
  };

  const groupPageMapping = {
    income: 'financial-and-career-stability',
    financialSecurity: 'financial-and-career-stability',
    careerSatisfaction: 'financial-and-career-stability',
    physicalHealth: 'health-and-fitness',
    fitnessLevel: 'health-and-fitness',
    mentalHealth: 'health-and-fitness',
    relationshipStatus: 'social-life-and-relationships',
    friends: 'social-life-and-relationships',
    homeSituation: 'social-life-and-relationships',
    commute: 'work-life-balance-and-leisure',
    workLifeBalance: 'work-life-balance-and-leisure',
    hobbies: 'work-life-balance-and-leisure',
  };

  const getDisplayName = (categoryCode) => categoryDisplayNames[categoryCode] || categoryCode;
  const getGroupPage = (categoryCode) => groupPageMapping[categoryCode] || 'general-resources';

  // Code for improvement potential and recommendation message
  const findMostRoomForImprovement = () => {
    const maxScores = {
      income: 10,
      relationshipStatus: 10,
      homeSituation: 8,
      fitnessLevel: 7,
      friends: 7,
      commute: 5,
      careerSatisfaction: 10,
      physicalHealth: 10,
      mentalHealth: 10,
      hobbies: 5,
      financialSecurity: 10,
      workLifeBalance: 8,
    };

    let improvementPotential = {};
    for (const category in categoryScores) {
      improvementPotential[category] = maxScores[category] - categoryScores[category];
    }

    const entries = Object.entries(improvementPotential);
    return entries.reduce((max, entry) => (entry[1] > max[1] ? entry : max), entries[0]);
  };

  const [categoryToImprove] = findMostRoomForImprovement();

  const improvementValue = 0;

      const improvementMessage = lifeScore === 99
  ? "Congratulations! You've achieved the highest score possible. Keep up the amazing work and continue thriving!"
  : categoryToImprove === "commute" && improvementValue <= 3
    ? "Looks like you are doing pretty well with your lowest category being commute, maybe just move closer lol."
    : (
        <span>
          To improve your {getDisplayName(categoryToImprove)}, we recommend checking out the resources on the
          <Link to={`/${getGroupPage(categoryToImprove)}`}> {getDisplayName(categoryToImprove)} page</Link> for more tips and guidance!
        </span>
      );

  return (
    <div className="results-center-container">
      <div className="result-back">
        <h1>Your LifeScore is: {lifeScore}</h1>
        <p>{lifeScore === 99 ? "You've maxed out (there is always something you can work on though, never settle!)" : randomMessage}</p>
        <p>{lifeScore === 99 ? "You've reached the highest score of 99! But remember, there's always room for improvement!" : "Great work!"}</p>
        <img src={randomGif} alt="reaction gif" />

        <div className="result-category-analysis">
          <p><strong>Your strongest area is:</strong> {lifeScore === 99 ? "Everything!" : getDisplayName(strongest[0])}</p>
          <p><strong>The area with most room for improvement:</strong> {lifeScore === 99 ? "Nothing? Just kidding! Maybe you're the next Buddha..." : getDisplayName(categoryToImprove)}.</p>
          <p>{improvementMessage}</p>
        </div>

        <button onClick={() => navigate('/input')}>Retake the Test</button>
        <button onClick={() => navigate('/')}>Return to Main Menu</button>
      </div>
    </div>
  );
};

export default Results;
