import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import InputForm from './InputForm';
import Results from './Results';
import ScrollToTop from './ScrollToTop'; // import the ScrollToTop component

import Home from './Home';
import HealthAndFitness from './HealthAndFitness';
import SocialLifeAndRelationships from './SocialLifeAndRelationships';
import WorkLifeBalanceAndLeisure from './WorkLifeBalanceAndLeisure';
import FinancialAndCareerStability from './FinancialAndCareerStability';


const App = () => {
  return (
    <Router>
      {/* ScrollToTop component will run on every route change */}
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/health-and-fitness" element={<HealthAndFitness />} />
        <Route path="/input" element={<InputForm />} />
        <Route path="/results" element={<Results />} />
        <Route path="/financial-and-career-stability" element={<FinancialAndCareerStability />} />
        <Route path="/social-life-and-relationships" element={<SocialLifeAndRelationships />} />
        <Route path="/work-life-balance-and-leisure" element={<WorkLifeBalanceAndLeisure />} />
      </Routes>
    </Router>
  );
};

export default App;
