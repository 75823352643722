import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Home.css';

const Home = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const formRef = useRef(null); // Reference to the subscription form
  const videoRef = useRef(null); // Reference to the video element

  const handleScrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const video = videoRef.current;

    // Set a random start time once video metadata (including duration) is loaded
    video.addEventListener('loadedmetadata', () => {
      const randomTime = Math.random() * video.duration;
      video.currentTime = randomTime; // Set video to the random start time
    });
  }, []);

  return (
    <div className="home-container">
      {/* Video Background */}
      <div className="video-container">
        <video
          ref={videoRef} // Reference to the video element
          autoPlay
          muted
          loop
          playsInline
          id="background-video"
        >
          <source src="/videos/DroneFootage.mp4" type="video/mp4" />

          Your browser does not support the video tag.
        </video>
      </div>

      {/* Header */}
      <div className="home-header">
        <h1>Welcome to LifeScore</h1>
        <p>Your Path to Self-Improvement and Well-being</p>
      </div>

      {/* Take the Quiz Button */}
      <button
        className="take-quiz-button"
        onClick={() => {
          navigate('/input'); // Navigate to quiz page
        }}
      >
        Take the Quiz
      </button>

      {/* Categories */}
      <div className="home-categories">
        <div className="home-category-box" onClick={() => navigate('/health-and-fitness')}>
          Health And Fitness
        </div>
        
        <div className="home-category-box" onClick={() => navigate('/financial-and-career-stability')}>
          Financial And Career Stability
        </div>
        
        <div className="home-category-box" onClick={() => navigate('/social-life-and-relationships')}>
          Social Life And Relationships
        </div>
        
        <div className="home-category-box" onClick={() => navigate('/work-life-balance-and-leisure')}>
          Work-Life Balance And Leisure
        </div>
      </div>

{/* Book of the Month */}
<div className="home-section-box">
  <h2>Book of the Month</h2>
  <p>
    <a href="https://amzn.to/3UfHJk5" target="_blank" rel="noopener noreferrer">
      Atomic Habits by James Clear (affiliate link)
    </a>
    <br /> {/* This forces the text to go to the next line */}
    <span>
      This is a great book for learning how to build habits that will lead to success in your goals and breaking habits that are holding you back!
    </span>
  </p>
</div>

      {/* Quote of the Week */}
      <div className="home-section-box">
        <h2>Quote of the Week</h2>
        <p>"The only limit to our realization of tomorrow is our doubts of today." - Franklin D. Roosevelt</p>
      </div>


      {/* Challenge of the Month */}
      <div className="home-section-box">
        <h2>Challenge of the Month</h2>
        <p>For ten minutes each day, go on a walk and think about all you are grateful for!</p>
      </div>

      {/* Subscribe Button */}
      <button
        className="subscribe-button"
        onClick={handleScrollToForm} // Scroll to the form when clicked
      >
        Don’t Miss Out! Get Your Free Self-Improvement Newsletter – Subscribe Now!
      </button>

      {/* Video of the Week */}
      <div className="video-of-the-week">
        <h2>Video of the Week</h2>
        <iframe
          width="800"
          height="450"
          src="https://www.youtube.com/embed/TBuIGBCF9jc"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Video of the Week"
        ></iframe>
      </div>

      {/* Embed the Mailchimp Form as an Iframe */}
      <div ref={formRef} className="subscribe-form-container">
        <iframe 
          src="/mailchimp-form.html" 
          title="Mailchimp Subscription Form" 
          style={{ width: '100%', height: '500px', border: 'none' }} 
        ></iframe>
      </div>


      {/* Contact Us Section */}
      <div className="contact-section">
        <p>Contact us at <a href="mailto:contact@lifescorequiz.com">contact@lifescorequiz.com</a></p>
      </div>
    </div>
  );
};

export default Home;
