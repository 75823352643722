import React from "react";
import { useNavigate } from "react-router-dom";
import './WorkLifeBalanceAndLeisure.css';

const WorkLifeBalanceAndLeisure = () => {
  const navigate = useNavigate(); // Hook should be called at the top level of the component

  return (
    <div className="work-page-container">
      {/* Header Section */}
      <h1 className="work-page-header">Work-Life Balance and Leisure Resources</h1>
      <p>It's essential to make time for what you love with those you love while leaving work at work!</p>

      {/* Resource Cards Section */}
      <section className="work-resource-section">
        <div className="work-resource-card">
          <a href="https://www.youtube.com/watch?v=MPR3o6Hnf2g" target="_blank" rel="noopener noreferrer">
            Work-Life Balance Youtube Video from The School Of Life
          </a>
        </div>

        <div className="work-resource-card">
          <a href="https://www.youtube.com/watch?v=BvpmZktlBFs" target="_blank" rel="noopener noreferrer">
            How To Find Your Purpose Youtube Video from Robert Greene and Dr. Andrew Huberman
          </a>
        </div>

        <div className="work-resource-card">
          <a href="https://www.smartsheet.com/content/how-to-improve-work-life-balance" target="_blank" rel="noopener noreferrer">
            How To Improve Work-Life Balance
          </a>
        </div>

        <div className="work-resource-card">
          <a href="https://zenhabits.net/intentionally/" target="_blank" rel="noopener noreferrer">
            Spending Your Time Intentionally
          </a>
        </div>

        <div className="work-resource-card">
          <a href="https://www.verywellmind.com/why-you-should-take-a-break-3144576" target="_blank" rel="noopener noreferrer">
            How to Take a Break from Work
          </a>
        </div>

        <div className="work-resource-card">
          <a href="https://amzn.to/3Ya7WBP" target="_blank" rel="noopener noreferrer">
          Pomodoro Timer, typically used for productivity but also a good reminder to take breaks! (affiliate link)
          </a>
        </div>

        <div className="work-resource-card">
          <a href="https://www.youtube.com/watch?v=6pgaJb2Wwhs" target="_blank" rel="noopener noreferrer">
            How To Find Your Passion YouTube Video by Improvement Pill
          </a>
        </div>

        <div className="work-resource-card">
          <a href="https://www.betterup.com/blog/how-to-find-a-hobby-as-an-adult" target="_blank" rel="noopener noreferrer">
            How To Find A Hobby That Fulfills You
          </a>
        </div>

        <div className="work-resource-card">
          <a href="https://www.wikihow.com/Find-a-Hobby" target="_blank" rel="noopener noreferrer">
            Your Ultimate Guide to Finding a Hobby You Enjoy
          </a>
        </div>

        <div className="work-resource-card">
          <a href="https://www.madebymaddisyn.com/blog/how-to-make-more-time-for-hobbies-and-interests" target="_blank" rel="noopener noreferrer">
            How To Make More Time For Hobbies And Interests
          </a>
        </div>

        <div className="work-resource-card">
          <a href="https://www.directionpsychology.com/article/the-importance-of-me-time/" target="_blank" rel="noopener noreferrer">
            The Importance of ME Time
          </a>
        </div>

        <div className="work-resource-card">
          <a href="https://www.youtube.com/watch?v=C6T9XVfcB1Y" target="_blank" rel="noopener noreferrer">
            Here's How To Stop Feeling Exhausted After Every Work Day Youtube Video from Business Insider
          </a>
        </div>
      </section>

      {/* Return to Main Menu Button */}
      <button className="work-back-button" onClick={() => navigate('/')}>
        Return to Main Menu
      </button>
    </div>
  );
};

export default WorkLifeBalanceAndLeisure;

