import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './InputForm.css';

const InputForm = () => {
  const [formData, setFormData] = useState({
    income: '',
    relationshipStatus: '',
    homeSituation: '',
    age: '',
    fitnessLevel: '',
    friends: '',
    commute: '',
    careerLifeSatisfaction: '',
    physicalHealth: '',
    mentalHealth: '',
    hobbies: '',
    financialSecurity: '',
    workLifeBalance: '',
    stayAtHomeParent: '',
    fullTimeStudent: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/results', { state: formData });
  };

  return (
    <div className="quiz-center-container">
      <div className="quiz-box">
        <form onSubmit={handleSubmit}>
          {/* Existing questions */}
          <label>
            Income:
            <select name="income" value={formData.income} onChange={handleChange}>
              <option value="">Select Income Range</option>
              <option value="0-9950">0 - 9,950</option>
              <option value="9951-40525">9,951 - 40,525</option>
              <option value="40526-86375">40,526 - 86,375</option>
              <option value="86376-164925">86,376 - 164,925</option>
              <option value="164926-209425">164,926 - 209,425</option>
              <option value="209426-523600">209,426 - 523,600</option>
              <option value="523601+">523,601+</option>
            </select>
          </label>

          <label>
            Relationship Status:
            <select name="relationshipStatus" value={formData.relationshipStatus} onChange={handleChange}>
              <option value="">Select Status</option>
              <option value="Single">Single</option>
              <option value="Talking">Talking to Someone</option>
              <option value="Dating">Dating</option>
              <option value="Married">Married</option>
            </select>
          </label>

          <label>
            Home Situation:
            <select name="homeSituation" value={formData.homeSituation} onChange={handleChange}>
              <option value="">Select Home Situation</option>
              <option value="Homeless">Homeless</option>
              <option value="Living with parents">Living with Parents</option>
              <option value="Living with roommates">Living with Roommates</option>
              <option value="Renting">Renting</option>
              <option value="Own my place">I Own My Own Place</option>
            </select>
          </label>

          <label>
            Age:
            <select name="age" value={formData.age} onChange={handleChange}>
              <option value="">Select Age</option>
              <option value="18-24">18-24</option>
              <option value="25-34">25-34</option>
              <option value="35-44">35-44</option>
              <option value="45-54">45-54</option>
              <option value="55-64">55-64</option>
              <option value="65+">65+</option>
            </select>
          </label>

          <label>
            Fitness Level:
            <select name="fitnessLevel" value={formData.fitnessLevel} onChange={handleChange}>
              <option value="">Select Fitness Level</option>
              <option value="Sedentary">Sedentary</option>
              <option value="Light Activity">Light Activity</option>
              <option value="Moderately Active">Moderately Active</option>
              <option value="Very Active">Very Active</option>
            </select>
          </label>

          <label>
            Friends (Number of Close Friends):
            <select name="friends" value={formData.friends} onChange={handleChange}>
              <option value="">Select Number of Friends</option>
              <option value="0">0</option>
              <option value="1-2">1-2</option>
              <option value="3-4">3-4</option>
              <option value="5+">5+</option>
            </select>
          </label>

          <label>
            Commute (Time to Work):
            <select name="commute" value={formData.commute} onChange={handleChange}>
              <option value="">Select Commute Time</option>
              <option value="No Commute">No Commute</option>
              <option value="0-15 min">0-15 min</option>
              <option value="15-30 min">15-30 min</option>
              <option value="30-60 min">30-60 min</option>
              <option value="1 hour+">1 hour+</option>
            </select>
          </label>

          {/* Updated Career & Life Satisfaction */}
          <label>
            Career & Life Satisfaction:
            <select name="careerLifeSatisfaction" value={formData.careerLifeSatisfaction} onChange={handleChange}>
              <option value="">How Satisfied are You in Your Career & Life?</option>
              <option value="Very Satisfied">Very Satisfied</option>
              <option value="Somewhat Satisfied">Somewhat Satisfied</option>
              <option value="Neutral">Neutral</option>
              <option value="Somewhat Dissatisfied">Somewhat Dissatisfied</option>
              <option value="Very Dissatisfied">Very Dissatisfied</option>
            </select>
          </label>

          {/* Separate Yes/No Questions */}
          <label>
            Are you a Stay-at-home Parent?
            <select name="stayAtHomeParent" value={formData.stayAtHomeParent} onChange={handleChange}>
              <option value="">Select Yes or No</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          <label>
            Are you a Full-time Student?
            <select name="fullTimeStudent" value={formData.fullTimeStudent} onChange={handleChange}>
              <option value="">Select Yes or No</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          <label>
            Physical Health:
            <select name="physicalHealth" value={formData.physicalHealth} onChange={handleChange}>
              <option value="">How is Your Physical Health?</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
              <option value="Very Poor">Very Poor</option>
            </select>
          </label>

          <label>
            Mental Health:
            <select name="mentalHealth" value={formData.mentalHealth} onChange={handleChange}>
              <option value="">How Is Your Mental Health?</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
              <option value="Very Poor">Very Poor</option>
            </select>
          </label>

          <label>
            Hobbies/Leisure:
            <select name="hobbies" value={formData.hobbies} onChange={handleChange}>
              <option value="">Select Hobbies/Leisure Frequency</option>
              <option value="Daily">Daily</option>
              <option value="A few times a week">A few times a week</option>
              <option value="Once a week">Once a week</option>
              <option value="Rarely">Rarely</option>
              <option value="Never">Never</option>
            </select>
          </label>

          <label>
            Financial Security:
            <select name="financialSecurity" value={formData.financialSecurity} onChange={handleChange}>
              <option value="">Select Financial Security</option>
              <option value="Very Secure">Very Secure</option>
              <option value="Somewhat Secure">Somewhat Secure</option>
              <option value="Neutral">Neutral</option>
              <option value="Somewhat Insecure">Somewhat Insecure</option>
              <option value="Very Insecure">Very Insecure</option>
            </select>
          </label>

          <label>
            Work-Life Balance:
            <select name="workLifeBalance" value={formData.workLifeBalance} onChange={handleChange}>
              <option value="">Select Work-Life Balance</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
              <option value="Very Poor">Very Poor</option>
            </select>
          </label>

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default InputForm;
