import React from "react";
import { useNavigate } from "react-router-dom";
import './HealthAndFitness.css';

const HealthAndFitness = () => {
    const navigate = useNavigate();

    return (
        <div className="health-and-fitness-pageContainer">
            
            <h1>Resources For Health And Fitness</h1>
            <p>Here are some things that might help you:</p>

            <div className="health-and-fitness-resourceCard">
              <a href="https://www.youtube.com/watch?v=37UhELFvPec" target="_blank" rel="noopener noreferrer">
              Power of Fitness TED Talk by Vincent Lam
              </a>
            </div>

            <div className="health-and-fitness-resourceCard">
              <a href="https://www.youtube.com/watch?v=rBUjOY12gJA&t=25s" target="_blank" rel="noopener noreferrer">
              What Happens To Your Body When You Start Exercising Regularly Youtube Video From InsiderTech
              </a>
            </div>

            <div className="health-and-fitness-resourceCard">
              <a href="https://amzn.to/4eRFcFa" target="_blank" rel="noopener noreferrer">
              Multifunctional Full Body Home Gym Equipment for Home Workout (affiliate link)
              </a>
            </div>
            
            <div className="health-and-fitness-resourceCard">
                <a href="https://www.muscleandfitness.com/workout-plan/workouts/workout-routines/complete-mf-beginners-training-guide-plan/#google_vignette"
                  target="_blank" rel="noopener noreferrer">
                    Beginner Workout Program
                </a>
            </div>

            <div className="health-and-fitness-resourceCard">
              <a href="https://www.canva.com/planners/templates/workout/" target="_blank" rel="noopener noreferrer">
                Free Workout Plan Templates
              </a>
            </div>

            <div className="health-and-fitness-resourceCard">
                <a href="https://www.cdc.gov/physicalactivity/index.html" target="_blank" rel="noopener noreferrer">
                  Physical Activity Guidelines From The CDC :O
                </a>
            </div>


            <div className="health-and-fitness-resourceCard">
              <a href="https://www.gerdhelp.com/blog/a-beginners-guide-to-healthy-eating/" target="_blank" rel="noopener noreferrer">
                A Beginner's Guide To Healthy Eating
              </a>
            </div>

            <div className="health-and-fitness-resourceCard">
              <a href="https://www.canva.com/menus/templates/meal-planner/" target="_blank" rel="noopener noreferrer">
              Free Meal Plan Templates
              </a>
            </div>

            <div className="health-and-fitness-resourceCard">
                <a href="https://amzn.to/4dOBYRn" target="_blank" rel="noopner noreferrer">
                The 30-Minute Mediterranean Diet Cookbook: 101 Easy, Flavorful Recipes for Lifelong Health (affiliate link)
                </a>
            </div>
          
            <div className="health-and-fitness-resourceCard">
                <a href="https://www.who.int/news-room/fact-sheets/detail/physical-activity" target="_blank" rel="noopener noreferrer">
                  Physical Activity Facts
                </a>
            </div>
          
            <div className="health-and-fitness-resourceCard">
                <a href="https://www.heart.org/en/healthy-living/fitness" target="_blank" rel="noopener noreferrer">
                  American Heart Association - Fitness
                </a>
            </div>
          
            <div className="health-and-fitness-resourceCard">
                <a href="https://www.mayoclinic.org/healthy-lifestyle/fitness" target="_blank" rel="noopener noreferrer">
                  Mayo Clinic - Fitness and Health
                </a>
            </div>

            <div className="health-and-fitness-resourceCard">
              <a href="https://www.mentalhealth.org.uk/explore-mental-health/publications/our-best-mental-health-tips"
              target="_blank" rel="noopener noreferrer">
                The Best Mental Health Tips- Backed By Research
              </a>
            </div>

            <div className="health-and-fitness-resourceCard">
              <a href="https://www.youtube.com/watch?v=BvpmZktlBFs" target="_blank" rel="noopener noreferrer">
              There's No Shame In Taking Care Of Your Mental Health TED Talk by Sangu Delle
              </a>
            </div>

            <div className="health-and-fitness-resourceCard">
              <a href="https://amzn.to/4ftrzMv" target="_blank" rel="noopener noreferrer">
              Mental Health Workbook: 6 Books in 1 (affiliate link)
              </a>
            </div>

            <div className="health-and-fitness-resourceCard">
                <a href="https://suicidepreventionlifeline.org" target="_blank" rel="noopener noreferrer">
                  Suicide Prevention Lifeline
                </a>
            </div>

            <div className="health-and-fitness-resourceCard">
                <a href="https://www.mentalhealth.gov" target="_blank" rel="noopener noreferrer">
                  MentalHealth.gov
                </a>
            </div>

          <div className="health-and-fitness-resourceCard">
            <a href="https://www.healthline.com/health/depression/benefits-sunlight#_noHeaderPrefixedContent"
            target="_blank" rel="noopener noreferrer">
              The Importance Of Getting Sun
            </a>
          </div>

            <div className="health-and-fitness-resourceCard">
                <a href="https://www.nami.org" target="_blank" rel="noopener noreferrer">
                  NAMI (National Alliance on Mental Illness)
                </a>
            </div>
          
            <button className="health-and-fitness-backButton" onClick={() => navigate('/')}>
                Return to Main Menu
            </button>
       </div>
    )
}

export default HealthAndFitness;
