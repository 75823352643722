import React from "react";
import { useNavigate } from "react-router-dom";
import './SocialLifeAndRelationships.css';

const SocialLifeAndRelationships = () => {
    const navigate = useNavigate();

    return (
        <div className="social-page-container">
            <h1>Resources To Improve Your Social Life And Relationships</h1>
            <p>I know socializing is hard post covid :P</p>

            <div className="social-resource-card">
                <a href="https://www.wikihow.com/Make-Friends" target="_blank" rel="noopener noreferrer">
                    How To Make Friends
                </a>
            </div>

            <div className="social-resource-card">
                <a href="https://www.youtube.com/watch?v=-BdbiZcNBXg" target="_blank" rel="noopener noreferrer">
                Being A Good Listener (Super Important Life Skill) Youtube Video from The School of Life
                </a>
            </div>

            <div className="social-resource-card">
                <a href="https://www.helpguide.org/relationships/social-connection/relationship-help" target="_blank" rel="noopener noreferrer">
                Tips For Building A Healthy Relationship
                </a>
            </div>

            <div className="social-resource-card">
                <a href="https://www.youtube.com/watch?v=gh5VhaicC6g" target="_blank" rel="noopener noreferrer">
                Skills For A Healthy Romantic Relationship TED Talk by Joanne Davila
                </a>
            </div>

            <div className="social-resource-card">
                <a href="https://www.healthline.com/health/how-to-be-more-social" target="_blank" rel="noopener noreferrer">
                Being More Social On Your Own Terms
                </a>
            </div>
        
            <div className="social-resource-card">
                <a href="https://markmanson.net/how-to-make-friends" target="_blank" rel="noopener noreferrer">
                    How To Make Friends As An Adult
                </a>
            </div>
        
            <div className="social-resource-card">
                <a href="https://nextbigideaclub.com/magazine/7-books-help-turn-anyone-friend/30148/" target="_blank" rel="noopener noreferrer">
                    Books To Help You Turn Anyone Into A Friend
                </a>
            </div>

            <div className="social-resource-card">
                <a href="https://www.youtube.com/watch?v=XIrQKo-d7h4" target="_blank" rel="noopener noreferrer">
                The Key To Dealing With Social Anxiety Youtube Video from Improvement Pill
                </a>
            </div>
        
            <div className="social-resource-card">
                <a href="https://www.youtube.com/watch?v=I9hJ_Rux9y0" target="_blank" rel="noopener noreferrer">
                    How To Make Friends Youtube Video from Kurzgesagt
                </a>
            </div>

            <div className="social-resource-card">
                <a href="https://www.youtube.com/watch?v=QXiAPiw7vfc" target="_blank" rel="noopener noreferrer">
                How To Always Have Something Interesting To Say Youtube Video from Charisma On Command
                </a>
            </div>

            <div className="social-resource-card">
                <a href="https://bemorewithless.com/how-to-be-a-good-friend/" target="_blank" rel="noopener noreferrer">
                How To Be A Good Friend
                </a>
            </div>

            <div className="social-resource-card">
                <a href="https://www.mindtools.com/at5m4h1/social-battery" target="_blank" rel="noopener noreferrer">
                How To Take Care Of Your Social Battery
                </a>
            </div>

            {/* Return to Main Menu Button */}
            <button className="social-back-button" onClick={() => navigate('/')}>Return to Main Menu</button>
        </div>
    )
}

export default SocialLifeAndRelationships;
